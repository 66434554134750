/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useNavigate } from 'react-router-dom';
import { useMemo, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

// sections
// mock

// ----------------------------------------------------------------------

export default function Profile() {
  return (
    <>
      <Helmet>
        <title> Personal Info | Voice Fuse </title>
      </Helmet>
      <div className='container'>
        <div className='card p-5'>
          <h1>Personal Info</h1>
          <Form >
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="validationCustom01" className='my-3'>
                <Form.Label>First name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="First name"
                  defaultValue="Mark"
                />

              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom02" className='my-3'> 
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Last name"
                  defaultValue="Otto"
                />

              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustomUsername" className='my-3'>
                <Form.Label>Email</Form.Label>
                
                  <Form.Control
                    type="email"
                    placeholder="email"
                    aria-describedby="inputGroupPrepend"
                    required
                  />

              </Form.Group>
            </Row>
            <Button type="submit">Submit form</Button>
          </Form>
        </div>
      </div>

    </>
  );
}
