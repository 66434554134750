import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Avatar, Box, Container, Typography } from '@mui/material';
// hooks
// import useResponsive from '../hooks/useResponsive';
import image from '../components/logo/voicefuse-01.png';
// components

// sections
import { LoginForm } from '../sections/auth/login';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    backgroundColor: "#fff"
  },
}));

// const StyledSection = styled('div')(({ theme }) => ({
//   width: '100%',
//   maxWidth: '50%',
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   boxShadow: theme.customShadows.card,
//   backgroundColor: 'black',
// }));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(1, 5),
  // backgroundColor: 'black',
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  // const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Login | Voice Fuse - Admin </title>
      </Helmet>

      <StyledRoot>
       
        <Container maxWidth="sm">
          <StyledContent>
          <Box sx={{ height: '160px', width: "100%"}}>
        <Avatar src={image} alt="" sx={{ height: 'auto', width: "50%", mx: "auto"}}/>
      </Box>

            <Typography variant="h3" sx={{  mt: 5, mb: 5, mx: 'auto', color: 'white' }}>
              Welcome To Admin Login
            </Typography>
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
