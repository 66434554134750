/* eslint-disable no-unused-vars */
import { useEffect, useState,useCallback } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// mocks_
import account from '../../../_mock/account';
import image from '../../../components/logo/voicefuse-02.png';

// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     icon: 'eva:home-fill',
//   },
//   {
//     label: 'Profile',
//     icon: 'eva:person-fill',
//   },
//   {
//     label: 'Settings',
//     icon: 'eva:settings-2-fill',
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [, setLoader] = useState(false);
  const [ProfileData, setProfileData] = useState('');
  const token = localStorage.getItem('accessToken');
const Navigate = useNavigate();

  const [open, setOpen] = useState(null);

  // const Navigate = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const logout = () => {
    localStorage.clear('accessToken');
    Navigate('/login');
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              // borderRadius: '50%',
              position: 'absolute',
              // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
       
        <Avatar src={image} alt="adimn Pic" />
        {/* {token ? (
          <Avatar src='/favicon/favicon.ico' alt="adimn Pic" />
        ) : (
          <Avatar src='/favicon/favicon.ico' alt="photoURL" />
        )} */}
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          {token ? (
            <Typography variant="subtitle2" noWrap>
               Voice Fuse
            </Typography>
          ) : (
            <Typography variant="subtitle2" noWrap>
              BFD-Admin
            </Typography>
          )}

          {token ? (
            <Typography variant="subtitle2" noWrap>
              {ProfileData?.email}
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {account.email}
            </Typography>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={logout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
