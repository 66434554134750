import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';

import DashboardAppPage from './pages/DashboardAppPage';
import Detail from './pages/Detail';
import Merchant from './pages/Merchant';
import Profile from './pages/Profile';
import Marketing from './pages/Marketing';
import AddMarketing from './pages/AddMarketing';
import AddManager from './pages/AddManager';
import AddProduct from './pages/AddProduct';

// ----------------------------------------------------------------------
export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'product', element: <UserPage /> },
        { path: 'addproduct', element: <AddProduct /> },
        { path:'manager', element:<Merchant/> },
        { path:'addmanager', element:<AddManager/> },
        { path:'profile', element:<Profile/> },
        { path:'marketing', element:<Marketing/> },
        { path:'addmarketing', element:<AddMarketing/> },
        { path: 'detail/:id', element: <Detail /> },
        { path: 'app', element: <DashboardAppPage /> },
      ],
    },

    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
