/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-undef */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ImageUploading from 'react-images-uploading';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';


const AddManager = () => {
    return (
        <>
            <Helmet>
                <title> Add Manager | Voice Fuse </title>
            </Helmet>
            <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Card style={{ width: '75%', margin: 'auto' }}>
                <CardContent>
                    <Typography variant='h3' sx={{ mb: 3, color: "black" }}>Add Manager</Typography>
                    <Box
                        sx={{
                            width: 600,
                            maxWidth: '100%',
                        }}
                    >
                        <TextField
                            fullWidth
                            id="fullWidth"
                            label="First Name"
                            sx={{ mb: 3,width: '120%' }}
                        //   onChange={(e) => setTitle(e.target.value)}
                        />
                        <p className='text-danger'>{Error?.title ? Error?.title[0] : null}</p>
                    </Box>
                    <Box
                        sx={{
                            width: 600,
                            maxWidth: '100%',
                        }}
                    >
                        <TextField
                            fullWidth
                            id="fullWidth"
                            label="Last Name"
                            sx={{ mb: 3,width: '120%' }}
                        //   onChange={(e) => setTitle(e.target.value)}
                        />
                        <p className='text-danger'>{Error?.title ? Error?.title[0] : null}</p>
                    </Box>
                    <Box
                        sx={{
                            width: 600,
                            maxWidth: '100%',
                        }}
                    >
                        <TextField
                            fullWidth
                            id="fullWidth"
                            label="Email"
                            sx={{ mb: 3,width: '120%' }}
                        //   onChange={(e) => setTitle(e.target.value)}
                        />
                        <p className='text-danger'>{Error?.title ? Error?.title[0] : null}</p>
                    </Box>
                   
                </CardContent>
                <CardActions>
                    <Button size="large" variant="contained" sx={{ backgroundColor: 'primary', my: 2 }} >
                        Add
                    </Button>
                </CardActions>
            </Card>
        </>
    )
}

export default AddManager