import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
// sections
import { AppWidgetSummary } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  
  const count = localStorage.getItem('form_count');


  return (
    <>
      <Helmet>
        <title> Dashboard | BFD </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Dashboard
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppWidgetSummary title="Forms Info" total={count} icon={'ant-design:user'} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
