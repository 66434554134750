/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Icon } from '@iconify/react';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { instance } from '../config/Http';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Merchant Name', alignRight: false },
  { id: 'Payment', label: 'Payment Method', alignRight: false },
  { id: 'is_active', label: 'Status', alignCenter: true },
  { id: 'action', label: 'View', alignCenter: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.submitter_business_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const Merchant = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [USERLIST, setUSERLIST] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.firstName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const token = localStorage.getItem('accessToken');

  const user = useCallback(() => {
    setLoader(true);

    instance
      .get('merchant')
      .then((response) => {
        setLoader(false);
        console.log(response, 'response');
        setUSERLIST(response?.data?.merchant);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
    console.log(token);
  }, [token]);

  useEffect(() => {
    user();
  }, [user]);

  const status = (id) => {
    setLoader(true);

    instance
      .get(`merchant/status/${id}`)
      .then((response) => {
        setLoader(false);
        console.log(response, 'response');
        if(response?.data?.status){
            Swal.fire({
                title: 'Good job!',
                text: response?.data?.message,
                icon: 'success',
                button: 'Ok',
              });
            user();
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
    console.log(token);
  };

  return (
    <>
      <Helmet>
        <title> Manager info | Voice Fuse </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Manager Info
          </Typography>
          <Button variant="contained" sx={{ backgroundColor: 'Primary' }} startIcon={<Iconify icon="eva:plus-fill" />} onClick={()=>navigate('/dashboard/addmanager')}>
            New Manager
          </Button> 
        </Stack>

        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, backgroundColor: 'white' }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  <Backdrop sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    // console.log(row, 'abdullah nay gande marwa li');
                    const {
                      id,
                      name,
                      payment,
                      is_active,
                     
                    } = row;
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell>
                          <Typography variant="subtitle2" noWrap>
                            {id}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{payment}</TableCell>
                    
                        <TableCell align="left">
                           { is_active===1? 
                        <Icon onClick={()=>status(id)}  icon="ph:toggle-right-fill" width="40" height="40" />:
                        <Icon onClick={()=>status(id)} icon="basil:toggle-off-solid" width="40" height="40" />}
                            </TableCell>

                        <TableCell style={{ width: '30%', display: 'flex' }}>
                          <MenuItem
                            sx={{ color: 'error.main' }}
                            onClick={() =>
                              navigate(`/dashboard/detail/${id}`, {
                                state: { data: USERLIST },
                              })
                            }
                          >
                            <Iconify icon={'eva:eye-outline'} sx={{ color: '#012367' }} />
                          </MenuItem>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
};

export default Merchant;
